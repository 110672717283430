import clsx from "classnames"
import { ImgixImage } from "common/components/ImgixImage"
import { ArrowRightIcon } from "@heroicons/react/20/solid"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { pageUrl } from "common/lib/router"
import { PartnersProps } from "./Partners"

const MAX_MOBILE_COUNT = 9
const MAX_COUNT = 20

/**
 * TODO: Replaced by AccordionPartners.view once all editions have moved to new partners block
 * Component for displaying partners, each one with their link and a link to the partners page.
 */
export const PartnersView = ({ partners = [] }: PartnersProps) => {
  const t = useTranslations("blocks.partners")
  const regionCode = useRegionCode()
  const partnersPath = pageUrl.view({ regionCode, pageSlug: "partners", prefix: false })

  return (
    <div>
      <div className="my-8 flex flex-wrap items-center justify-around gap-4 sm:gap-8">
        {partners.slice(0, MAX_COUNT).map(({ path, logoUrl, name }, index) => (
          <a
            key={index}
            href={path}
            className={clsx("flex-none", { "hidden lg:block": index + 1 > MAX_MOBILE_COUNT })}
          >
            <ImgixImage
              src={logoUrl}
              alt={name}
              dimensions={{ height: 100, width: 100 }}
              fit="fillmax"
            />
          </a>
        ))}
      </div>
      <a href={partnersPath} className="ml-2 flex gap-3 text-sm font-bold sm:ml-4">
        {t("view_all_partners")}
        <ArrowRightIcon className="inline size-5 flex-none" />
      </a>
    </div>
  )
}
