import dynamic from "next/dynamic"
import { Partner } from "common/types/graphql"
import { PartnersView } from "./Partners.view"
import { AccordionPartnersView } from "./AccordionPartners.view"
import { usePageProps } from "common/hooks/data/usePageProps"
import { EditPageProps } from "pages/edit/index.page"

const PartnersEdit = dynamic<PartnersProps>(() =>
  import("./Partners.edit").then((mod) => mod.PartnersEdit),
)

export interface PartnersProps {
  partners?: Partner[]
  explainer?: string
  partnerLinkDescription?: string
}

export const Partners = (props: PartnersProps) => {
  const { editing } = usePageProps<EditPageProps>()

  // TODO: Can be removed once all partner blocks have been moved over to new format
  const ViewComponent = props.explainer ? AccordionPartnersView : PartnersView
  const PartnersComponent = editing ? PartnersEdit : ViewComponent

  return <PartnersComponent {...props} />
}
