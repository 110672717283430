import NextImage, { ImageLoaderProps } from "next/image"
import { Crop, Dimensions } from "common/types/graphql"
import { useState } from "react"
import { ImagePlaceholder } from "common/components/ImagePlaceholder"

const DEFAULT_IMGIX_SETTINGS = { q: "30", usm: "12", cs: "strip" }

// Imgix resize fit mode documentation: https://docs.imgix.com/apis/rendering/size/fit
type ImgixFitValues =
  | "clamp"
  | "clip"
  | "crop"
  | "facearea"
  | "fill"
  | "fillmax"
  | "max"
  | "min"
  | "scale"

interface ImgixLoaderProps extends ImageLoaderProps {
  aspectRatio?: string | null
  crop?: Crop | null
  dimensions?: Dimensions
  fit?: ImgixFitValues
}

/**
 * Method to generate an Imgix URL for the image. It implements the Next.js Image loader interface.
 * @see https://nextjs.org/docs/api-reference/next/image#loader
 */
const imgixLoader = ({
  src,
  width,
  quality,
  crop,
  dimensions,
  aspectRatio,
  fit,
}: ImgixLoaderProps): string => {
  const searchParams = new URLSearchParams({
    ...DEFAULT_IMGIX_SETTINGS,
    w: width.toString(),
    auto: "format",
  })

  // `next/image` loader will assign different widths to the image depending on the device pixel ratio.
  // We need to scale the image height accordingly.
  // While resizing the image to fit within the given dimensions, we're going to crop it to avoid
  // stretching the image.
  //
  // if the dimensions are not provided, but an aspect ratio is, infer the height from w * aspectRatio
  if (dimensions) {
    const scale = width / dimensions.width
    const imageHeight = Math.trunc(dimensions.height * scale)

    searchParams.set("h", imageHeight.toString())
    searchParams.set("fit", "crop")
  } else if (aspectRatio) {
    const [x, y] = aspectRatio.split(":")
    const fractionalAspectRatio = Number(y) / Number(x)

    const imageHeight = Math.trunc(width * fractionalAspectRatio)
    searchParams.set("fit", "crop")
    searchParams.set("h", imageHeight.toString())
  }

  if (quality) {
    searchParams.set("q", quality.toString())
  }

  if (crop) {
    const { x, y, width, height } = crop

    searchParams.set("rect", `${x},${y},${width},${height}`)
    searchParams.set("fit", "crop")
  }

  if (fit) {
    searchParams.set("fit", fit)
  }

  return `${src}?${searchParams}`
}

/**
 * RGB data URL for a placeholder image.
 * @see https://github.com/vercel/next.js/blob/2725a379bb130632ef51b2f2eacef7b7ed9783b6/examples/image-component/pages/color.tsx#L14-L17
 */
export const rgbDataURL = (r: number, g: number, b: number) => {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="

  const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63)

  return `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
  }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`
}

/**
 * Converts a hex colour string to an RGB value.
 *
 * If the given hex colour string doesn't match the expected format, then it
 * returns `null`.
 *
 * @param hex The hex colour string to be converted.
 */
export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

// this is "onyx" in the design system
const ONYX_RGB = { r: 75, g: 75, b: 78 }

const OBJECT_FIT_TO_CLASSNAME = {
  cover: "object-cover",
  contain: "object-contain",
}

export interface ImgixImageProps {
  src: string
  alt?: string
  aspectRatio?: string
  placeholderColor?: string
  dimensions?: Dimensions
  sizes?: string
  crop?: Crop | null
  fit?: ImgixFitValues
  objectFit?: "cover" | "contain"
  className?: string
}

/**
 * A component that renders an Imgix image with a placeholder.
 *
 * The image will fill the parent container, having an absolute position. It
 * will require that the parent container has a relative, fixed or absolute
 * position style, as well as a width and height.
 *
 * @see https://nextjs.org/docs/api-reference/next/image#fill
 */
export const ImgixImage = ({
  src,
  alt = "",
  aspectRatio,
  placeholderColor = "",
  dimensions,
  sizes,
  crop,
  fit,
  objectFit = "cover",
  className,
}: ImgixImageProps) => {
  const [imageError, setImageError] = useState(false)
  const rgbColor = hexToRgb(placeholderColor) || ONYX_RGB
  const blurDataURL = rgbDataURL(rgbColor.r, rgbColor.g, rgbColor.b)

  return (
    <>
      <NextImage
        src={src}
        loader={(props) => imgixLoader({ ...props, aspectRatio, crop, dimensions, fit })}
        alt={alt}
        placeholder="blur"
        blurDataURL={blurDataURL}
        className={
          imageError ? "hidden" : className ? className : OBJECT_FIT_TO_CLASSNAME[objectFit]
        }
        loading="lazy"
        fill={!dimensions}
        width={dimensions?.width}
        height={dimensions?.height}
        sizes={sizes}
        onError={() => setImageError(true)}
        onLoad={() => setImageError(false)}
      />
      {imageError && (
        <figure>
          <ImagePlaceholder />
        </figure>
      )}
    </>
  )
}
