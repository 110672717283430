import { Partner } from "common/types/graphql"
import { useTranslations } from "modules/i18n/hooks/useTranslations"
import { usePageProps } from "common/hooks/data/usePageProps"
import { EditPageProps } from "pages/edit/index.page"
import { ChevronUpIcon } from "@heroicons/react/24/solid"
import { ArrowRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid"
import clsx from "classnames"
import { ImgixImage } from "common/components/ImgixImage"
import { PartnersProps } from "./Partners"
import { useState } from "react"
import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import { pageUrl } from "common/lib/router"

/**
 * TODO: New version of partners block, once all pages have been migrated, can replace Partners.view
 */

const MOBILE_MAX_COUNT = 6
const TABLET_PLUS_MAX_COUNT = 5

export const AVAILABLE_PARTNER_TYPES = [
  "founding_partner",
  "funding_partner",
  "member",
  "strategic_partner",
  "endorsing_partner",
  "content_partner",
  "media_partner",
  "hosting_partner",
  "technology_partner",
]

interface PartnerTypeSectionsProps {
  partners?: Partner[]
  type: string
  isActive: boolean
  partnersPath: string
  onClick: () => void
}

export const PartnerTypeSections = ({
  partners = [],
  type,
  isActive,
  onClick,
  partnersPath,
}: PartnerTypeSectionsProps) => {
  const t = useTranslations("blocks.partners")
  const { editing } = usePageProps<EditPageProps>()

  if (partners.length === 0) {
    return null
  }

  const partnerPathForType = partnersPath + `#${type.replace(/_/g, "-")}s`

  return (
    <div key={type}>
      <div
        aria-expanded={isActive}
        aria-controls={`partner_list_for_${type}`}
        aria-label={isActive ? t(`hide_${type}`) : t(`show_${type}`)}
        /* eslint-disable-next-line tailwindcss/no-custom-classname */
        className="partners-block partners-block-accordion flex w-full border-b pb-3"
        onClick={onClick}
      >
        <h3 className="font-sans-heading text-base font-bold leading-6 lg:text-xl">{t(type)}</h3>
        <div className="ml-auto">
          {isActive && <ChevronUpIcon className="size-5" />}
          {!isActive && <ChevronDownIcon className="size-5" />}
        </div>
      </div>
      <div
        id={`partner_list_for_${type}`}
        className={clsx("transition-all duration-500 ease-in-out", {
          "max-h-96 visible opacity-100": isActive,
          "max-h-0 invisible opacity-0": !isActive,
        })}
      >
        {/*
         * NOTE: grid-cols-5 is tied to TABLET_PLUS_MAX_COUNT and grid-cols-3 is half of MOBILE_MAX_COUNT
         * But tailwind doesn't like interpolation.
         */}
        <div className="my-6 grid grid-cols-3 items-center justify-items-center gap-6 sm:grid-cols-5 lg:gap-y-10">
          {partners.map(({ path, logoUrl, name }, index) => (
            <a
              key={index}
              href={path}
              /* eslint-disable-next-line tailwindcss/no-custom-classname */
              className={clsx(
                "partners-block partners-block-click-through m-1 w-20 sm:w-14 md:w-16 lg:w-24",
                {
                  hidden: index + 1 > MOBILE_MAX_COUNT,
                  "sm:hidden": index + 1 > TABLET_PLUS_MAX_COUNT,
                  "w-14 sm:w-10 lg:w-12": editing,
                },
              )}
            >
              <ImgixImage
                src={logoUrl}
                alt={name}
                dimensions={{ height: 100, width: 150 }}
                fit="clip"
              />
            </a>
          ))}
        </div>
        <a href={partnerPathForType} className="mb-8 flex text-sm font-medium">
          {t(`view_all_${type}`)}
          <ArrowRightIcon className="ml-1 inline size-5 flex-none" />
        </a>
      </div>
    </div>
  )
}

/**
 * Basic group by function as Object.groupBy is not supported on enough browsers yet
 */
const partnerGroupByType = (partners?: Partner[]) => {
  if (!partners) return []

  return partners.reduce((reducer, partner) => {
    reducer[partner.type] = reducer[partner.type] || []
    reducer[partner.type].push(partner)
    return reducer
  }, Object.create(null))
}

export interface PartnerExplainerProps {
  explainer?: string
  partnerLinkDescription?: string
  partnersPath: string
}

const PartnerExplainer = ({
  explainer,
  partnerLinkDescription,
  partnersPath,
}: PartnerExplainerProps) => (
  <div className="col-span-full mr-16 break-words sm:col-span-3">
    {explainer && <p>{explainer}</p>}
    {partnerLinkDescription && partnerLinkDescription.length > 0 && (
      <a href={partnersPath} className="my-4 flex gap-1 text-sm font-medium">
        {partnerLinkDescription}
        <ArrowRightIcon className="inline size-5 flex-none" />
      </a>
    )}
  </div>
)

export const AccordionPartnersView = ({
  partners = [],
  explainer,
  partnerLinkDescription,
}: PartnersProps) => {
  const partnersByType = partnerGroupByType(partners)

  const [activePartnerIndex, setActivePartnerIndex] = useState(0)

  // Don't show sections if there's no partners within it
  const partnerTypesToShow = AVAILABLE_PARTNER_TYPES.filter(
    (partnerType) => partnersByType[partnerType],
  )
  const regionCode = useRegionCode()
  const partnersPath = pageUrl.view({
    regionCode,
    pageSlug: "partners",
    prefix: false,
  })

  return (
    <div className="grid grid-flow-col grid-cols-8 gap-6">
      {explainer && (
        <PartnerExplainer
          partnersPath={partnersPath}
          explainer={explainer}
          partnerLinkDescription={partnerLinkDescription}
        />
      )}
      <div className={clsx("col-span-full", { "sm:col-span-5": explainer })}>
        {partnerTypesToShow.map((partnerType: string, index: number) => (
          <PartnerTypeSections
            isActive={activePartnerIndex === index}
            key={partnerType}
            type={partnerType}
            partners={partnersByType[partnerType]}
            partnersPath={partnersPath}
            onClick={() => setActivePartnerIndex(activePartnerIndex === index ? -1 : index)}
          />
        ))}
      </div>
    </div>
  )
}
